import React from "react";
import "./../styles/global.scss";
import NavbarCustom from "./../components/NavbarCustom";
import IndexPage from "./index";
import PricingPage from "./pricing";
import ContactPage from "./contact";
import { Switch, Route, Router } from "./../util/router";
import NotFoundPage from "./404";
import Footer from "./../components/Footer";

function App(props) {
  return (
    <Router>
      <>
        <NavbarCustom
          bg="white"
          variant="light"
          expand="md"
          logo="https://s3.amazonaws.com/cdn.robotlogogenerator.com/app-generate-logo.svg"
        />

        <Switch>
          <Route exact path="/" component={IndexPage} />

          <Route exact path="/pricing" component={PricingPage} />

          <Route exact path="/contact" component={ContactPage} />

          <Route component={NotFoundPage} />
        </Switch>

        <Footer
          bg="light"
          textColor="dark"
          size="sm"
          bgImage=""
          bgImageOpacity={1}
          copyright={`© ${new Date().getFullYear()} Generate My App Inc.`}
          logo="https://s3.amazonaws.com/cdn.robotlogogenerator.com/app-generate-logo.svg"
        />
      </>
    </Router>
  );
}

export default App;
