import React from "react";
import Meta from "./../components/Meta";
import HeroSection from "./../components/HeroSection";
import FeaturesSection from "./../components/FeaturesSection";
import ContactSection from "../components/ContactSection";

function IndexPage(props) {
  return (
    <>
      <Meta />
      <HeroSection
        bg="white"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        title="You describe, We generate"
        subtitle="Describe your app idea, and we'll generate the app for free. Enter a description of your app, and we'll respond back with a url to your demo app."
        image="https://s3.amazonaws.com/cdn.robotlogogenerator.com/Hero+Image.png"
        buttonText="Submit"
        buttonColor="primary"
      />
      <FeaturesSection
        bg="white"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        title="How it works"
        subtitle="You give a description, our AI app generator returns a demo url. The demo is yours for free."
      />
      <ContactSection
        bg="white"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        title="Contact Us"
        subtitle=""
        buttonText="Send message"
        buttonColor="primary"
        showNameField={true}
      />

    </>
  );
}

export default IndexPage;
